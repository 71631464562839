<template>
  <div style="background: white">
    <!-- <HeaderBar /> -->
    <v-sheet class="overflow-hidden">
      <Loading :loadingCounter="loadingCounter" />
      <NavBar tittle="label.lbl_warehouseMasterList" />
      <v-form ref="form">
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- 営業所 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="officeSelected"
                :items="officeList"
                :label="$t('label.lbl_salesOffice')"
                persistent-hint
                :hint="setOffice()"
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 倉庫名/倉庫コード -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="warehouseSelected"
                :items="warehouseList"
                :label="$t('label.lbl_warehouseName')"
                persistent-hint
                :hint="setWarehouse()"
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- <span class="require">*</span> -->
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 倉庫種別 -->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="WarehouseKindSelected"
                :items="WarehouseKindList"
                :label="$t('label.lbl_warehouseKinds')"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 社有外傭区分 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="companyOwnedSelected"
                :items="companyOwnedList"
                :label="$t('label.lbl_companyOwnedGaijin')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template></v-autocomplete
              >
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="searchWarehouse('get-search')"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
              </div>
              <div class="btn-area">
                <!--コピーボタン-->
                <!-- <v-btn color="primary" id="btn-search" class="api-btn" @click="copy">{{
                  $t("btn.btn_copy")
                }}</v-btn> -->
                <!--新規登録ボタン-->
                <v-btn color="primary" id="btn-search" class="api-btn" @click="initRegist">{{
                  $t("btn.btn_iniRegist")
                }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :server-items-length="itemsPerPage"
            disable-filtering
            :options.sync="sortOptions"
            :hide-default-footer="true"
            height="530px"
          >
            <!-- ロケ登録ボタン -->
            <template v-slot:[`item.locationAdd`]="{ item }">
              <v-btn small @click="openlocationGroupDialog(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <!-- ロケ詳細ボタン -->
            <template v-slot:[`item.locationDetail`]="{ item }">
              <v-btn small @click="openLocationDetail(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          </div>
        </v-container>
      </v-form>
      <v-dialog v-model="locationGroupDialog" :max-width="350" origin:top>
        <!-- ロケグループダイアログ -->
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name"> {{ $t("label.lbl_locationGroupSelect") }}</span>
          </v-card-title>
          <v-form ref="numIssueVali" lazy-validation>
            <v-container>
              <v-row>
                <div class="add-textbox-num">
                  <v-autocomplete
                    outlined
                    dense
                    v-model="locationGroup"
                    :items="locationGList"
                    :label="$t('label.lbl_locationGroupName')"
                    clear-icon="mdi-close-circle"
                  >
                    <template slot="item" slot-scope="data">
                      <span class="auto-txt-size"> {{ data.item.text }} </span>
                    </template>
                  </v-autocomplete>
                </div>
                <span class="require" style="padding-top: 20px">*</span>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="search-row-exeBtn">
              <div class="btn-search-area" style="float: left">
                <v-btn class="other-btn" style="margin-left: 0" @click="locationGclose()">
                  {{ $t("btn.btn_close") }}
                </v-btn>
                <v-btn class="post-btn" @click="locationGsave()">
                  {{ $t("btn.btn_select") }}
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import commonMixin from "@/mixins/CommonMixin";

export default {
  name: appConfig.SCREEN_ID.P_MST_002,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
    // officeSelected: { type: Object, default: null },
  },
  mixins: [commonMixin],
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    // 検索ボタン押下後、trueにする
    // ボタン表示の切り替え
    btnToggle: false,
    // 入力チェック結果フラグ
    isInputCheck: true,
    // 総件数
    totalCount: 0,
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // メニュー
    openMenu: null,
    dateMenu: false,
    // ログインユーザーの営業所SID
    user: "",
    // 登録画面に表示させる営業所名
    displayofficeName: "",
    officeSid: "",
    // ログインユーザーのofficeSID
    loginofficeSid: "",
    // 営業所プルダウン
    officeSelected: "",
    officeList: [],
    // 倉庫名/倉庫コードプルダウン
    warehouseList: [],
    warehouseSelected: "",
    itemSelected: "",
    // 倉庫種別プルダウン
    WarehouseKindList: [],
    WarehouseKindSelected: "",
    // 社有外傭区分プルダウン
    companyOwnedList: [],
    companyOwnedSelected: "",
    floorSpaceTsubo: "",
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    locationGroupDialog: false,
    locationGroup: "",
    locationGList: [],
    // テーブルフッター項目
    inputList: [],
    // ソート
    sortItem: "",
    sortOptions: {},
    // ロケグループダイアログ一時保存変数
    inputwarehouseSid: "",
    inputofficeSid: "",
    inputwarehouseCdName: "",
    inputofficeSelected: "",
    inputwarehouseSelected: "",
    inputWarehouseKindSelected: "",
    inputcompanyOwnedSelected: "",
    inputbackpage: "",
    // ロケーションGr登録ダイアログ
    dialogNewLocationGr: false,
    popNewLocation: "",
    alertMessageInUnit: "",
    // テーブルヘッダ項目
    headerItems: [
      // 営業所名
      {
        text: i18n.tc("label.lbl_salesOffice"),
        value: "officeName",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // 倉庫コード
      {
        text: i18n.tc("label.lbl_warehouseCd"),
        value: "warehouseCd",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // 倉庫名称
      {
        text: i18n.tc("label.lbl_warehouseName"),
        value: "warehouseName",
        width: "8%",
        align: "left",
        sortable: false,
      },
      // 倉庫種別
      {
        text: i18n.tc("label.lbl_warehouseKinds"),
        value: "warehouseKindName",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // 社有外傭区分
      {
        text: i18n.tc("label.lbl_companyOwnedGaijin"),
        value: "warehouseOwnerDivName",
        align: "left",
        width: "4%",
        sortable: true,
      },
      // 有効期間
      {
        text: i18n.tc("label.lbl_validityPeriod"),
        value: "validityPeriod",
        align: "left",
        width: "5%",
        sortable: true,
      },
      // 延床面積
      {
        text:
          i18n.tc("label.lbl_totalFloorAreaSqmetre") +
          `(${appConfig.CONSTANT.TOTALFLOORAREASQMETER})`,
        value: "totalFloorAreaSqmetreName",
        align: "right",
        width: "5%",
        sortable: true,
      },
      // ロケ登録
      {
        text: i18n.tc("label.lbl_locationAdd"),
        value: "locationAdd",
        align: "center",
        width: "2%",
        sortable: false,
      },
      // ロケ詳細
      {
        text: i18n.tc("label.lbl_warehouseDetail"),
        value: "locationDetail",
        align: "center",
        width: "2%",
        sortable: false,
      },
    ],
  }),
  methods: {
    // ページング
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.searchWarehouse("get-search");
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.searchWarehouse("get-search");
    },
    // 初期化
    init() {
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      // ログインユーザーの営業所SIDを取得
      this.user = sessionStorage.getItem("sales_office_sid");
      this.getCustomInfo();
      this.getcompanyOwnedGaijin();
      this.getMstCode();
      // ログインユーザーの営業所SIDを営業所プルダウンにセット
      this.officeSelected = this.user;
      this.getWarehouse();
      this.referenceDate = dateTimeHelper.convertJST();
      this.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      //倉庫マスタ登録画面で戻るボタンを押下した場合
      if (this.$route.params.backpage == "MstWarehouseAdd") {
        Object.assign(this, this.$route.params.searchFilter);
        if (
          this.$route.params.searchFilter &&
          Object.keys(this.$route.params.searchFilter).length !== 0
        ) {
          this.searchWarehouse("get-search");
        }
      } else if (this.$route.params.backpage == "MstLocationAdd") {
        Object.assign(this, this.$route.params.searchFilter);
        if (
          this.$route.params.searchFilter &&
          Object.keys(this.$route.params.searchFilter).length !== 0
        ) {
          this.searchWarehouse("get-search");
        }
      }
    },
    // ロケグループダイアログ
    openlocationGroupDialog(item) {
      this.locationGroupDialog = true;
      this.editedIndex = this.inputList.indexOf(item);
      // ロケ登録へ値を送るように保管
      this.inputwarehouseSid = this.inputList[this.editedIndex].warehouseSid;
      this.inputofficeSid = this.inputList[this.editedIndex].officeSid;
      this.inputwarehouseCdName = this.inputList[this.editedIndex].warehouseCdName;
      this.inputofficeSelected = this.officeSelected;
      this.inputwarehouseSelected = this.warehouseSelected;
      this.inputWarehouseKindSelected = this.WarehouseKindSelected;
      this.inputcompanyOwnedSelected = this.companyOwnedSelected;
      this.inputbackpage = "MstWarehouseList";
      this.getLocationG();
    },
    locationGclose() {
      this.locationGroup = "";
      this.locationGroupDialog = false;
    },
    // ロケ登録画面遷移
    locationGsave() {
      if (this.$refs.numIssueVali.validate()) {
        if (!this.locationGroup == "") {
          this.$router.push({
            name: appConfig.SCREEN_ID.P_MST_003,
            params: {
              warehouseSid: this.inputwarehouseSid,
              officeSid: this.inputofficeSid,
              warehouseCdName: this.inputwarehouseCdName,
              locationGroup: this.locationGroup,
              // 検索条件
              searchFilter: this.searchFilter,
              backpage: this.inputbackpage,
            },
          });
        }
        this.locationGroupDialog = false;
      }
    },
    // 倉庫マスタ検索
    searchWarehouse(val) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.isMst = "0";
      config.params.searchCategory = "0";

      // 営業所
      if (this.officeSelected) {
        config.params.officeSid = this.officeSelected;
      }

      // 倉庫名
      if (this.warehouseSelected) {
        config.params.warehouseSid = this.warehouseSelected;
      }

      // 倉庫種別
      if (this.WarehouseKindSelected) {
        config.params.warehouseKind = this.WarehouseKindSelected;
      }

      // 社有外傭区分
      if (this.companyOwnedSelected) {
        config.params.warehouseOwnerDiv = this.companyOwnedSelected;
      }

      // ページャー処理
      if (val == "get-search") {
        config.params.reqComPageIndex = "1";
        this.page = 1;
      } else {
        if (this.page >= 2 && this.inputList.length == 0) {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          config.params.reqComPageIndex = this.page;
        }
      }
      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;
      // ソート
      if (this.sortItem != "") {
        config.params.reqComSortItem = this.sortItem;
      }

      // console.debug("searchWarehouse(checkBoxFlag) Config", config);
      this.loginofficeSid = config.params.reqComOfficeSid;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_WAREHOUSE, config)
          .then((response) => {
            // console.debug("searchWarehouse(checkBoxFlag) Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              const list = [];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  officeName: row.officeName, // 営業所名
                  officeSid: row.officeSid, // 営業SID
                  warehouseCd: row.warehouseCd, // 倉庫コード
                  warehouseCdName:
                    `${row.warehouseCd}` + `(${row.warehouseLanguage[0].warehouseName})`,
                  warehouseSid: row.warehouseLanguage[0].warehouseSid, //倉庫SID
                  warehouseName: row.warehouseLanguage[0].warehouseName, // 倉庫名称
                  warehouseNameKana: row.warehouseLanguage[0].warehouseNameKana, // 倉庫名称カナ
                  warehouseNameShort: row.warehouseLanguage[0].warehouseNameShort, // 倉庫名称表示用
                  warehouseKind: row.warehouseKind, // 倉庫種別
                  warehouseKindName: row.warehouseKindName, // 倉庫種別（表示用）
                  warehouseOwnerDiv: row.warehouseOwnerDiv, //社有外傭区分
                  warehouseOwnerDivName: row.warehouseOwnerDivName, //社有外傭区分（表示用）
                  validityPeriod: `${dateTimeHelper
                    .convertUTC2JST(row.validFrom)
                    .substr(0, 10)}-${dateTimeHelper.convertUTC2JST(row.validTo).substr(0, 10)}`, //有効期間
                  validFrom: row.validFrom, //有効開始日時
                  validTo: row.validTo, //有効終了日時
                  totalFloorAreaSqmetre: row.floorSpace, //延床面積(㎡)
                  totalFloorAreaSqmetreName: Number(row.floorSpace).toLocaleString(), //延床面積(㎡)表示用数値型にしてからカンマ区切り
                  floorSpaceTsubo: row.floorSpaceTsubo, //延床面積(坪)
                  ceilingHeight: row.ceilingHeight,
                  floorLoadCapacity: row.floorLoadCapacity,
                  updateDatetime: row.warehouseLanguage[0].updateDatetime, //更新日時
                });
              });
              this.inputList = list;

              // 検索値保持
              Object.assign(this.searchFilter, {
                officeSelected: this.officeSelected,
                warehouseSelected: this.warehouseSelected,
                WarehouseKindSelected: this.WarehouseKindSelected,
                companyOwnedSelected: this.companyOwnedSelected,
              });

              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 倉庫修正画面遷移
    openLocationDetail(item) {
      // 選択した行のアイテム
      this.editedIndex = this.inputList.indexOf(item);
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MST_001,
        params: {
          // 競合した時のために更新日時も渡す
          updateDatetime: this.inputList[this.editedIndex].updateDatetime,
          // 営業所名
          officeName: this.inputList[this.editedIndex].officeName,
          // 営業所SID
          officeSid: this.inputList[this.editedIndex].officeSid,
          // ログインしているユーザーの営業所SID
          loginofficeSid: this.user,
          // 倉庫SID
          warehouseSid: this.inputList[this.editedIndex].warehouseSid,
          // 倉庫コード
          warehouseCd: this.inputList[this.editedIndex].warehouseCd,
          // 倉庫名称
          warehouseName: this.inputList[this.editedIndex].warehouseName,
          // 倉庫名称表示用
          warehouseNameShort: this.inputList[this.editedIndex].warehouseNameShort,
          // 倉庫名称カナ
          warehouseNameKana: this.inputList[this.editedIndex].warehouseNameKana,
          // 倉庫種別
          warehouseKind: this.inputList[this.editedIndex].warehouseKind,
          // 社有外傭区分
          warehouseOwnerDiv: this.inputList[this.editedIndex].warehouseOwnerDiv,
          // 有効開始日時
          validFrom: this.inputList[this.editedIndex].validFrom,
          // 有効終了日時
          validTo: this.inputList[this.editedIndex].validTo,
          // 延床面積（㎡）
          totalFloorAreaSqmetre: this.inputList[this.editedIndex].totalFloorAreaSqmetre,
          // 延床面積（坪）
          floorSpaceTsubo: this.inputList[this.editedIndex].floorSpaceTsubo,
          ceilingHeight: this.inputList[this.editedIndex].ceilingHeight,
          floorLoadCapacity: this.inputList[this.editedIndex].floorLoadCapacity,
          // 画面名
          name: "MstWarehouseList",
          // 検索条件
          searchFilter: this.searchFilter,
        },
      });
    },
    // 倉庫プルダウン
    getWarehouse() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      if (this.officeSelected) {
        config.params.officeSid = this.officeSelected;
      }
      config.params.isMst = "1";
      config.params.searchCategory = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_WAREHOUSLIST, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  text: `${row.warehouseLanguage[0].warehouseName}` + `(${row.warehouseCd})`,
                  value: row.warehouseLanguage[0].warehouseSid,
                  name: row.warehouseLanguage[0].warehouseName,
                  officeName: row.officeName,
                  officeSid: row.officeSid,
                });
              });
              this.warehouseList = list;
              this.warehouseList.unshift({ name: "", text: "", value: "", officeSid: "" });
              // this.officeSid = this.warehouseList[1].officeSid;
              // this.displayofficeName = this.warehouseList[1].officeName;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 倉庫種別プルダウン
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const methodList = getParameter.getCodeMst(appConfig.CODETYPE.WAREHOUSE_TIPE);

      Promise.all([methodList])
        .then((result) => {
          // console.debug("getMstCode() Result", result);
          this.WarehouseKindList = result[0];
          this.WarehouseKindList.unshift({ name: "", text: "", value: "" });
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 社有外傭プルダウン
    getcompanyOwnedGaijin() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const methodList = getParameter.getCodeMst(appConfig.CODETYPE.companyOwned_DIV);

      Promise.all([methodList])
        .then((result) => {
          // console.debug("getcompanyOwnedGaijin() Result", result);
          this.companyOwnedList = result[0];
          this.companyOwnedList.unshift({ name: "", text: "", value: "" });
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // ロケグループ取得
    getLocationG() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      // 検索区分
      config.params.searchCategoryDiv = "0";
      // 処理区分
      config.params.processDiv = "02";
      config.params.reqComComponentId = appConfig.SCREEN_ID.P_MST_002; // 画面ID
      config.params.officeSid = this.inputofficeSid; // 営業所SID
      config.params.warehouseSid = this.inputwarehouseSid; // 倉庫SID
      // console.debug("getLocationG() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_LOCATIONBIZ, config)
          .then((response) => {
            // console.debug("getLocationG() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [
                {
                  text: i18n.tc("label.lbl_locationAdd"),
                  value: "product0",
                  name: i18n.tc("label.lbl_locationAdd"),
                  isNew: true,
                },
              ];
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  text: `${i18n.tc("label.lbl_locaGroup") + row.locationGroup}`,
                  value: row.locationGroup,
                });
              });
              this.locationGList = list;

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // ロケGセット
    setLocationG() {
      for (var i = 0; i < this.locationsList.length; i++) {
        if (this.locationsList[i].value == this.locationsList) {
          return this.locationsList[i].name;
        }
      }
    },
    // ポップアップを閉じる
    closeDatailsAdd() {
      this.openDatailsAdd = false;
    },
    // 営業所プルダウン
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.isMst = "1";
      config.params.relatedPartyDiv = "02";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATED, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getCustomInfo() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [{ text: "", name: "", value: "" }];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                if (row.relatedPartyLanguage.length > 0) {
                  list.push({
                    text: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                    value: row.relatedPartyLanguage[0].relatedPartySid ?? "",
                    name: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                  });
                }
              });
              this.officeList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 新規登録ボタン押下
    initRegist() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MST_001,
        params: {
          officeSid: this.user,
          // 検索条件
          searchFilter: this.searchFilter,
        },
      });
    },
    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;
        }
        this.isAllSelected = false;
      }
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * 倉庫リストボックス取得処理
     */
    setWarehouse() {
      for (var i = 0; i < this.warehouseList.length; i++) {
        if (this.warehouseList[i].value == this.warehouseSelected) {
          return this.warehouseList[i].name;
        }
      }
    },
    /**
     * 営業所リストボックス取得処理
     */
    setOffice() {
      for (var i = 0; i < this.officeList.length; i++) {
        if (this.officeList[i].value == this.officeSelected) {
          return this.officeList[i].name;
        }
      }
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    referenceDateCal(val) {
      this.referenceDate = this.formatDate(val);
    },
    page(newValue) {
      this.page = newValue;
      this.searchWarehouse("");
    },
    officeSelected(newValue) {
      if (newValue || null) {
        this.officeSelected = newValue;
        this.warehouseSelected = "";
        this.getWarehouse();
      } else {
        this.officeSelected = newValue;
        this.getWarehouse();
      }
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MST_002_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.searchWarehouse();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.overflow {
  overflow: hidden;
}

.toatlNum {
  margin-left: 10px;
}

.txt-center {
  margin: auto;
}

.link {
  text-decoration: none;
}

.add-textbox-num {
  width: 16rem;
  padding: 20px 0 0 10px;
}
</style>
